import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));
const PageBuilder = loadable(() =>
  import("../components/templates/PageBuilder")
);
const PageHero = loadable(() => import("@organisms/PageHero"));
const CaseStudyIndex = loadable(() => import("@organisms/CaseStudyIndex"));

const ResourcesIndexPage = ({ data, ...rest }) => {
  const { hero, entries, categories, blocks } = data;
  return (
    <PageContainer {...rest} flex={false}>
      <PageHero {...hero} color="slate" />
      <CaseStudyIndex entries={entries} categories={categories} />
      <PageBuilder blocks={blocks} />
    </PageContainer>
  );
};

ResourcesIndexPage.defaultProps = {};

export default ResourcesIndexPage;
